import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';

import { CheckoutContext } from './context';

import { Bold, BorderRadius } from '../../utils/variables';
import './payments.css';

import loadComponents from '../Loadable';
import { HeadingPlaceholder, PayButtonPlaceholder } from './placeholders';

const H5 = loadComponents('h5');
const ButtonHidden = loadComponents('button-hidden');
const ButtonSubmit = loadComponents('button-submit');

export default class Payments extends React.Component {
  cardPayment = () => {
    const { checkout } = this.context;
    const { processing } = checkout;
    const payButton = `Pay Now`;
    return (
      <div
        className="cardPaymentOuter"
        style={{
          display: `flex`,
          justifyContent: `space-between`,
        }}
      >
        <CardElement options={{ hidePostalCode: true }} />
        {payButton && (
          <div style={{ flex: `0 0 25%` }}>
            <ButtonSubmit
              label={payButton}
              processing={processing}
              style={{
                width: `100%`,
              }}
              fallback={<PayButtonPlaceholder />}
              primary
            >
              {payButton}
            </ButtonSubmit>
          </div>
        )}
      </div>
    );
  };

  invoicePayment = () => {
    const { checkout } = this.context;
    const { processing } = checkout;
    const payButton = `Request Invoice`;
    return (
      <ButtonSubmit
        label={payButton}
        processing={processing}
        style={{
          width: `350px`,
          maxWidth: `100%`,
        }}
        fallback={<PayButtonPlaceholder />}
        primary
      >
        {payButton}
      </ButtonSubmit>
    );
  };

  tabRadius = (i, count) => {
    if (i === 0) {
      return `${BorderRadius} 0 0 0`;
    }

    if (i === count - 1) {
      return `0 ${BorderRadius} 0 0`;
    }

    return ``;
  };

  render() {
    const { checkout, togglePayment } = this.context;
    const { payment } = checkout;

    const payments = [
      {
        title: 'Credit/Debit Card',
        gateway: this.cardPayment,
      },
      {
        title: 'Request Pro Forma Invoice',
        gateway: this.invoicePayment,
      },
    ];

    return (
      <div className="paymentOuter" style={{ marginTop: `30px` }}>
        <H5
          style={{ textTransform: `uppercase` }}
          fallback={<HeadingPlaceholder />}
        >
          Payment <span>Method</span>
        </H5>
        <ul className="paymentTabs" style={tabStyle}>
          {payments.map((item, i) => (
            <li
              key={item.title}
              className={`tab${payment === i ? ` active` : ``}`}
              style={{
                ...tabLi,
                backgroundColor: payment === i ? `#fff` : `#f7f7f7`,
                borderWidth: i === 0 ? `1px 1px 0 1px` : `1px 1px 0 0`,
                borderRadius: this.tabRadius(i, payments.length),
              }}
            >
              <ButtonHidden
                onClick={e => togglePayment(e, i)}
                style={tabButton}
                fallback={
                  <div
                    style={{
                      width: `200px`,
                      height: `41px`,
                      marginBottom: `8px`,
                    }}
                  />
                }
              >
                {item.title}
              </ButtonHidden>
            </li>
          ))}
        </ul>
        <div className="paymentInner" style={paymentStyle}>
          {payments[payment].gateway()}
        </div>
      </div>
    );
  }
}

Payments.contextType = CheckoutContext;

const tabStyle = {
  margin: 0,
};

const tabLi = {
  borderStyle: `solid`,
  borderColor: `rgba(0,0,0,0.2)`,
  position: `relative`,
  cursor: `pointer`,
  fontSize: `0.875rem`,
  fontWeight: Bold,
  textAlign: `center`,
  display: `flex`,
  alignItems: `center`,
  justifyContent: `center`,
};

const tabButton = {
  padding: `18px 45px`,
  textTransform: `uppercase`,
};

const paymentStyle = {
  border: `1px solid rgba(0,0,0,.2)`,
  padding: `1.875rem`,
};
