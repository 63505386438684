/* eslint-disable import/prefer-default-export */
import React from 'react';
import { MainColour } from '../../utils/variables';

import Placeholder from '../Placeholder';

export const HeadingPlaceholder = () => (
  <Placeholder
    width="300px"
    height="36px"
    colour="#222"
    style={{ marginTop: `7px`, marginBottom: `45px` }}
  />
);

export const PayButtonPlaceholder = () => (
  <Placeholder
    width="100%"
    height="61px"
    colour={MainColour}
    style={{ borderRadius: `100px` }}
  />
);
